import dayjs from 'dayjs';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { ICustomVisitDelivery, IDistributor, IDistributorForm } from 'core/model/interfaces/distributors';
import type { ISalesForces } from 'core/model/interfaces/master-entities';
import type {
  IGetDistributorResponseDTO,
  IGetDistributorsDataResponse,
  IGetSalesForceResponseDTO,
  IRequestDistributorUpdateDTO,
} from './distributors.dto';

export const mapDtoToDistributorsDisplay = (response: IGetDistributorsDataResponse): IGetDistributorsDataResponse => ({
  ...response,
  distributors: response.distributors.map(
    (distributor: IDistributor): IDistributor => ({
      ...distributor,
      dateUpdate: distributor.dateUpdate ? dayjs(distributor.dateUpdate).format('DD/MM/YYYY') : '',
    }),
  ),
});

export const distributorResponseSalesForce = (
  response: IGetSalesForceResponseDTO,
): { salesForceList: Array<ISalesForces> } => {
  const salesForceList = response.externalSalesForces.map(externalSalesForce => ({
    id: externalSalesForce.id,
    externalPriceGroups: externalSalesForce.externalPriceGroups.map(externalPriceGroup => ({
      priceGroupCode: externalPriceGroup.id,
      priceGroupDescription: externalPriceGroup.description,
    })),
  }));

  return { salesForceList };
};

export const distributorDetailResponseToDistributorDetailForm = (
  response: IGetDistributorResponseDTO,
): {
  distributorForm: IDistributorForm;
  visitDeliveryList: Array<ICustomVisitDelivery>;
} => {
  const visitDeliveryList = response.visitDelivery.map(delivery => ({
    customerVisitDeliveryId: delivery.id,
    description: delivery.description,
    selected: delivery.selected,
  }));

  const selectedVisitDelivery = visitDeliveryList.find(v => v.selected)?.customerVisitDeliveryId || undefined;

  const distributorForm: IDistributorForm = {
    distributorData: {
      distributorName: response.externalBranch.description,
      distributorCode: response.externalDistributorId,
      branchCode: response.externalBranch.id,
      zoneName: response.externalZone.description,
      zoneCode: response.externalZone?.id,
    },
    visitDelivery: selectedVisitDelivery,
    minimumPurchases: response.minimumPurchase.map(minimumPurchaseItem => ({
      id: minimumPurchaseItem.id,
      isNew: false,
      isEditing: false,
      applyTo: minimumPurchaseItem.amount ? EApplyToOption.CART : EApplyToOption.BRIEFCASE,
      amountAlicorp: minimumPurchaseItem.amountAlicorp,
      amountAllied: minimumPurchaseItem.amountAllied,
      amount: minimumPurchaseItem.amount,
      customers: (minimumPurchaseItem.externalCustomerIds || []).join('\n'),
      priceGroup: (minimumPurchaseItem?.externalPriceGroups || []).map(externalPriceGroupItem => ({
        priceGroupCode: externalPriceGroupItem.id,
        priceGroupDescription: externalPriceGroupItem.description,
      })),
      customerPriceList: (minimumPurchaseItem.externalPriceLists || []).map(externalPriceListItem => ({
        priceListCode: externalPriceListItem.id,
        priceListDescription: externalPriceListItem.description,
        additionalInformation: null,
      })),
      zones: minimumPurchaseItem.externalZones || [],
      externalSalesForce: minimumPurchaseItem.externalSalesForceId,
    })),
    taxes: {
      perception: response.perceptionFlag,
    },
  };

  return { distributorForm, visitDeliveryList };
};

export const distributorFormToDistributorDto = (body: IDistributorForm): IRequestDistributorUpdateDTO => ({
  minimumPurchase: body.minimumPurchases.map(minimumPurchase => ({
    id: minimumPurchase.id,
    amountAlicorp: minimumPurchase.applyTo === EApplyToOption.BRIEFCASE ? minimumPurchase.amountAlicorp : undefined,
    amountAllied: minimumPurchase.applyTo === EApplyToOption.BRIEFCASE ? minimumPurchase.amountAllied : undefined,
    amount: minimumPurchase.applyTo === EApplyToOption.CART ? minimumPurchase.amount : undefined,
    externalSalesForceId: minimumPurchase.externalSalesForce ?? null,
    externalZoneIds: minimumPurchase.zones.map(z => z.id) || [],
    externalPriceGroupIds: minimumPurchase.priceGroup.map(p => p.priceGroupCode) || [],
    externalPriceListIds: minimumPurchase.customerPriceList.map(p => p.priceListCode) || [],
    externalCustomerIds: minimumPurchase.customers.trim() !== '' ? minimumPurchase.customers.split(`\n`) : [],
  })),
  visitDelivery: {
    id: body.visitDelivery,
  },
});
