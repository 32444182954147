import { useFormContext } from 'react-hook-form';
import { Form } from '@alicorpdigital/dali-components-react';
import type { IDistributorForm } from 'core/model/interfaces/distributors';

import './distributor-tax.scss';

export const DistributorTax = () => {
  const { watch } = useFormContext<IDistributorForm>();

  const perception = watch('taxes.perception');

  return (
    <div className="distributor-tax">
      <h5 className="distributor-tax__subtitle">4. Impuesto</h5>
      <p>¿Aplica percepción?</p>
      <div className="distributor-tax__grid">
        <Form.Group>
          <Form.Input value={perception ? 'Si' : 'No'} disabled />
        </Form.Group>
      </div>
    </div>
  );
};
