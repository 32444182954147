import { useFormContext } from 'react-hook-form';
import { Form } from '@alicorpdigital/dali-components-react';
import type { IDistributorForm } from 'core/model/interfaces/distributors';

import './distributor-data.scss';

export const DistributorData = () => {
  const { watch } = useFormContext<IDistributorForm>();

  const distributorData = watch('distributorData');
  const externalZoneInfo = `${distributorData.zoneCode} - ${distributorData.zoneName}`;

  return (
    <div className="distributor-data">
      <h5 className="distributor-data__subtitle">1. Datos de operador</h5>
      <div className="distributor-data__grid">
        <Form.Group>
          <Form.Label>Nombre del operador</Form.Label>
          <Form.Input value={distributorData.distributorName} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Oficina / Zona geográfica</Form.Label>
          <Form.Input value={externalZoneInfo} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Compañía</Form.Label>
          <Form.Input value={distributorData.distributorCode} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sucursal</Form.Label>
          <Form.Input value={distributorData.branchCode} disabled />
        </Form.Group>
      </div>
    </div>
  );
};
