import { useFormContext } from 'react-hook-form';
import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomVisitDelivery } from 'core/model/interfaces/distributors';

import './distributor-mirror-days.scss';

interface IDistributorMirrorDaysProps {
  customVisitDeliveries: Array<ICustomVisitDelivery>;
}

export const DistributorMirrorDays = ({ customVisitDeliveries }: IDistributorMirrorDaysProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="distributors-mirror">
      <h5 className="distributors-mirror__subtitle">2. Días espejo en fechas de entrega</h5>
      <p className="distributors-mirror__paragraph">Selecciona una opción de día espejo para una fecha de entrega.</p>
      <Form.Group>
        <div className="distributors-mirror__grid">
          {customVisitDeliveries.map(visitDelivery => (
            <Form.Check
              defaultChecked={visitDelivery.selected}
              key={visitDelivery.customerVisitDeliveryId}
              type="radio"
              label={visitDelivery.description}
              id={visitDelivery.customerVisitDeliveryId.toString()}
              value={visitDelivery.customerVisitDeliveryId.toString()}
              {...register('visitDelivery')}
            />
          ))}
          {errors.visitDelivery && <Form.HelperText isInvalid>{errors.visitDelivery.message}</Form.HelperText>}
        </div>
      </Form.Group>
    </div>
  );
};
